<template>
  <ion-item>
    <ion-label :class="`${!isLast ? 'no-space' : 'no-space-last'}`">
      <div class="date" v-if="isNewDate">{{ formattedDate }}</div>
      <div :class="`${fromUser ? 'right' : 'left'}`">
        <h2 class="name" v-if="isFirst">{{ name }}</h2>
        <div class="message">
          {{ text }}
        </div>
        <div class="time" v-if="isLast">
          {{ justTime }}
        </div>
      </div>
    </ion-label>
    <ion-avatar :slot="`${fromUser ? 'end' : 'start'}`"
      ><ion-img v-if="isLast" :src="`${avatarUrl}`"></ion-img
    ></ion-avatar>
  </ion-item>
</template>

<script>
import { IonItem, IonLabel, IonAvatar, IonImg } from "@ionic/vue";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "Message",
  components: {
    IonItem,
    IonLabel,
    IonAvatar,
    IonImg,
  },
  props: {
    name: String,
    text: String,
    date: Number,
    avatarUrl: String,
    id: Number,
    // user: String,
    fromUser: Boolean,
    order: Number,
  },
  data() {
    return {
      // fromUser: false,
      isLast: true,
      isFirst: true,
      isNewDate: true,
    };
  },
  computed: {
    formattedDate() {
      return moment
        .unix(this.date)
        .locale("cs")
        .format("LL");
    },
    justTime() {
      return moment
        .unix(this.date)
        .locale("cs")
        .format("LT");
    },
    ...mapGetters({
      getChatDetailMessages: "chat/getChatDetailMessages",
    }),
  },
  beforeMount() {
    let messages = this.getChatDetailMessages;
    if (this.order > 0) {
      if (messages[this.order - 1].id == this.id) {
        this.isFirst = false;
      }
    }
    if (this.order < messages.length - 1) {
      if (messages[this.order + 1].id == this.id) {
        this.isLast = false;
      }
    }
    if (this.order > 0) {
      if (
        moment
          .unix(messages[this.order - 1].created_at)
          .locale("cs")
          .format("l") ==
        moment
          .unix(this.date)
          .locale("cs")
          .format("l")
      ) {
        this.isNewDate = false;
      }
    }
  },
};
</script>

<style scoped>
.no-space-last {
  margin-right: 0px;
  margin-left: 0px;
  margin-top: 5px;
}
.no-space {
  margin: 0px;
  margin-top: 5px;
}
ion-item {
  --padding-start: 0px;
  --inner-padding-end: 0px;
}
.message {
  background: var(--ion-color-step-150);
  white-space: break-spaces;
  padding: 15px;
  border-radius: 15px;
  max-width: 90%;
  float: left;
}
.time {
  padding-left: 15px;
}
.name {
  padding-left: 15px;
  font-weight: 700;
}
.right {
  text-align: right;
}
.right .message {
  float: right;
  background: var(--ion-color-primary);
  color: #fff;
  text-align: left;
}
.right .time {
  clear: both;
  padding-right: 15px;
}
.right .name {
  clear: both;
  padding-right: 15px;
}
.left {
  text-align: left;
}
.left .message {
  float: left;
  background: var(--ion-color-step-150);
  text-align: left;
}
.left .time {
  clear: both;
  padding-left: 15px;
}
.left .name {
  clear: both;
  padding-left: 15px;
}
.date {
  text-align: center;
  margin: 15px 0px;
  /* margin-right: 36px; */
}
ion-avatar {
  align-self: flex-end;
  margin: 0;
}
</style>
