<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button @click="closeFunc()">
          <ion-icon :icon="close"> </ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-buttons slot="end">
        <ion-button
          :disabled="!canBePosted"
          @click="isNewChat ? newChat() : addToChat()"
        >
          {{ isNewChat ? "Vytvořit" : "Přidat" }}
        </ion-button>
      </ion-buttons>
      <ion-title>
        {{
          isNewChat ? "Vytvořit nový chat" : "Přidat uživatele do chatu"
        }}</ion-title
      >
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list lines="full">
      <ion-item v-for="item in getListOfUsers" :key="item.id">
        <ion-label
          ><ion-img :src="`${item.avatar_url}`"></ion-img
          ><span class="name">{{ item.name }}</span></ion-label
        >
        <ion-checkbox
          color="primary"
          :checked="checkUser(item.id)"
          :disabled="checkUser(item.id)"
          slot="start"
          @click="selectUsers(item.id)"
          :value="item.id"
        ></ion-checkbox>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import {
  IonIcon,
  IonButton,
  IonButtons,
  IonTitle,
  IonToolbar,
  IonHeader,
  IonContent,
  IonImg,
  IonLabel,
  IonCheckbox,
  IonItem,
  IonList,
  modalController,
} from "@ionic/vue";
import { mapActions, mapGetters } from "vuex";
import { close } from "ionicons/icons";
import _ from "lodash";
export default {
  name: "NewChatModal",
  components: {
    IonIcon,
    IonButton,
    IonButtons,
    IonTitle,
    IonToolbar,
    IonHeader,
    IonContent,
    IonImg,
    IonLabel,
    IonCheckbox,
    IonItem,
    IonList,
  },
  props: {
    isNewChat: Boolean,
    chatId: Number,
    usersInChat: Array,
  },
  data() {
    return {
      selectedUsers: [],
      canBePosted: false,
    };
  },
  setup() {
    return {
      close,
    };
  },
  //   watch: {
  //     selectedUsers(value) {
  //       console.log("checked", value);
  //     },
  //   },
  computed: {
    ...mapGetters({
      getActiveId: "social/getActiveId",
      getListOfUsers: "chat/getListOfUsers",
    }),
  },
  async created() {
    this.downloadAllUsers({ id: this.getActiveId });
  },
  methods: {
    ...mapActions({
      downloadAllUsers: "chat/downloadAllUsers",
      createNewChat: "chat/createNewChat",
      addUserToChat: "chat/addUserToChat",
    }),
    closeFunc() {
      modalController.dismiss({
        dismissed: true,
        created: true,
      });
    },
    selectUsers(id) {
      console.log("sel", id, this.selectedUsers);
      if (this.selectedUsers.includes(id)) {
        this.selectedUsers = _.without(this.selectedUsers, id);
      } else {
        this.selectedUsers.push(id);
      }
      if (this.selectedUsers.length > 0) {
        this.canBePosted = true;
      } else {
        this.canBePosted = false;
      }
      console.log("canBePosted", this.canBePosted, this.selectedUsers);
    },
    newChat() {
      // alert("NEW");
      this.createNewChat({
        network: this.getActiveId,
        users: this.selectedUsers,
      }).then((res) => {
        let conversationId = res.group.id;
        this.$router.push("/chat/" + conversationId);
        this.closeFunc();
        // console.log("CHAT", res);
      });
    },
    addToChat() {
      this.addUserToChat({
        networkId: this.getActiveId,
        chatId: this.chatId,
        users: this.selectedUsers,
      }).then((res) => {
        // let conversationId = res.group.id;
        // this.$router.go();
        this.closeFunc();
        console.log("CHAT", res);
      });
      // alert("ADD");
    },
    checkUser(userId) {
      let find = _.findIndex(this.usersInChat, (o) => {
        return o.id == userId;
      });
      console.log("CHECK", find);
      if (find == -1) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>
.name {
  position: relative;
  top: -17px;
  margin-left: 10px;
  font-weight: 700;
  /* font-size: 16px; */
}
ion-img {
  display: inline-block;
  width: 48px;
}
ion-content {
  --ion-background-color: var(--ion-card-background);
  --background: var(--ion-card-background);
  background: var(--background);
}
</style>
