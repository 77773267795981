<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="() => $router.back()">
            <ion-icon :icon="chevronBackOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button @click="addToChat()">
            <ion-icon :icon="addOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>{{ names }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-list lines="none">
        <ion-item v-if="getChatDetailMessages.length == 0">
          <ion-label style="text-align: center;">
            <h2>Ještě zde nic není.</h2>
            <p>Pošli první zprávu.</p>
          </ion-label>
        </ion-item>
        <Message
          v-for="(item, index) in getChatDetailMessages"
          :key="item.id"
          :id="item.id"
          :name="item.name"
          :date="item.created_at"
          :text="item.text"
          :fromUser="item.id == getUserId ? true : false"
          :avatarUrl="item.avatarUrl"
          :order="index"
        />
      </ion-list>
    </ion-content>
    <ion-footer class="ion-padding">
      <ion-toolbar>
        <ion-textarea
          v-model="messageText"
          rows="1"
          autoGrow
          placeholder="Napiš zprávu..."
        >
        </ion-textarea>
        <ion-buttons slot="end">
          <ion-button :disabled="!canBePosted" @click="sendMsg()">
            <ion-icon :icon="sendOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonButton,
  IonIcon,
  IonList,
  IonFooter,
  IonTitle,
  IonToolbar,
  IonHeader,
  IonButtons,
  IonTextarea,
  modalController,
  IonItem,
  IonLabel,
} from "@ionic/vue";
import { mapActions, mapGetters } from "vuex";
import { chevronBackOutline, sendOutline, addOutline } from "ionicons/icons";
import Message from "../components/Message.vue";
import NewChatModal from "../components/NewChatModal.vue";

export default {
  name: "ChatDetail",
  data() {
    return {
      id: 0,
      users: [],
      messages: [],
      names: "",
      messageText: "",
      canBePosted: false,
    };
  },
  components: {
    IonPage,
    IonContent,
    IonFooter,
    IonIcon,
    IonButton,
    IonButtons,
    IonTitle,
    IonToolbar,
    IonHeader,
    IonTextarea,
    IonList,
    Message,
    IonItem,
    IonLabel,
  },
  computed: {
    ...mapGetters({
      getActiveId: "social/getActiveId",
      getChatDetailMessages: "chat/getChatDetailMessages",
      getChatDetailUsers: "chat/getChatDetailUsers",
      getUserId: "auth/getUserId",
    }),
  },
  watch: {
    messageText(value) {
      this.messageText = value;
      // console.log("val", this.messageText, this.canBePosted);
      if (this.messageText.length > 0) {
        this.canBePosted = true;
      } else {
        this.canBePosted = false;
      }
    },
  },
  created() {},
  async beforeMount() {
    await this.getData();
  },
  mounted() {
    let content = document.querySelector("ion-content");
    content.scrollToBottom(500);
  },
  methods: {
    ...mapActions({
      downloadChatDetail: "chat/downloadChatDetail",
      sendMessage: "chat/sendMessage",
      setChatDetailId: "chat/setChatDetailId",
    }),
    async addToChat() {
      let self = this;
      const modal = await modalController.create({
        component: NewChatModal,
        cssClass: "new-chat-modal",
        componentProps: {
          isNewChat: false,
          usersInChat: self.users,
          chatId: self.id,
        },
      });

      modal.present();

      modal.onDidDismiss().then(async (data) => {
        self.getData();
      });
    },
    async getData() {
      this.names = "";
      this.id = this.$route.params.id;
      this.setChatDetailId({ id: this.id });
      await this.downloadChatDetail({
        networkId: this.getActiveId,
        chatId: this.id,
      });
      this.users = this.getChatDetailUsers;

      this.users.forEach((el, index) => {
        if (el.id != this.getUserId) {
          this.names += el.name;
          console.log("INDEX", index + 1, this.users.length);
          if (
            index + 1 != this.users.length &&
            this.users[index + 1].id != this.getUserId
          ) {
            this.names += ", ";
          }
        }
      });
    },
    sendMsg() {
      this.sendMessage({
        networkId: this.getActiveId,
        chatId: this.id,
        text: this.messageText,
      }).then((res) => {
        this.messageText = "";
        console.log("message sent", res);
      });
    },
  },
  setup() {
    return {
      chevronBackOutline,
      sendOutline,
      addOutline,
    };
  },
};
</script>

<style scoped>
ion-content,
ion-footer {
  --ion-background-color: var(--ion-card-background);
}
ion-footer {
  padding: 0px;
}
ion-footer ion-icon {
  position: relative;
  top: 4px;
  left: 2px;
}
ion-textarea {
  position: relative;
  top: 2px;
  display: inline-block;
  background: var(--ion-color-step-150);
  border-radius: 24px;
  border: 1px solid #e2e8f0;
  margin: 0 5px;
  --padding-start: 10px;
}
</style>
